/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { forwardRef, type ReactNode, useRef } from 'react';

import { cssMap, cx, jsx } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { List } from '../../../components/list';

import { LevelContext, useIsExpanded, useLevel } from './expandable-menu-item-context';

const styles = cssMap({
	content: {
		// This specific padding value is used to achieve alignment of content
		// when nesting expandable menu items.
		paddingInlineStart: `calc(${token('space.300')} + ${token('space.025')})`,
	},
	collapsedContent: {
		display: 'none',
	},
});

export type ExpandableMenuItemContentProps = {
	children: ReactNode;
};

/**
 * __ExpandableMenuItemContent__
 *
 * The expandable and collapsable section of the expandable menu item. It should contain the nested menu items.
 */
export const ExpandableMenuItemContent = forwardRef<HTMLDivElement, ExpandableMenuItemContentProps>(
	({ children }, forwardedRef) => {
		const isExpanded = useIsExpanded();
		const level = useLevel();
		const hasExpanded = useRef(false);

		if (!isExpanded && !hasExpanded.current) {
			return null;
		}

		hasExpanded.current = true;

		return (
			<LevelContext.Provider value={level + 1}>
				<List ref={forwardedRef} xcss={cx(styles.content, !isExpanded && styles.collapsedContent)}>
					{children}
				</List>
			</LevelContext.Provider>
		);
	},
);
