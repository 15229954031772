/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { forwardRef, type ReactNode, type Ref, useEffect, useRef } from 'react';

import { jsx, type XCSSProp } from '@compiled/react';

import mergeRefs from '@atlaskit/ds-lib/merge-refs';

export type ListProps = {
	children: ReactNode;
	xcss?: XCSSProp<'alignItems' | 'display' | 'gap' | 'paddingInlineStart', never>;
};

function _List({ children, xcss }: ListProps, forwardedRef: Ref<HTMLDivElement>) {
	const internalRef = useRef<HTMLUListElement>(null);

	useEffect(() => {
		if (process.env.NODE_ENV !== 'production') {
			if (internalRef.current) {
				Array.from(internalRef.current.children).forEach((child) => {
					if (isValidChild(child)) {
						return;
					}

					// eslint-disable-next-line no-console
					console.error(
						'List Composition Error\n\n',
						'This element is not a semantically valid list item:\n\n',
						child,
						'\n\nThis is an accessibility issue and must be fixed.\n\n',
						'This message will not be displayed in production.',
					);
				});
			}
		}
	}, []);

	const ref = mergeRefs([internalRef, forwardedRef]);

	return (
		/**
		 * We are using `role="list"` instead of a `ul` element to enable more flexible
		 * composition. By using ARIA roles we can validly have elements between a list
		 * and list items, as long as those in-between elements have no semantics.
		 */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
		<div role="list" ref={ref} className={xcss}>
			{children}
		</div>
	);
}

/**
 * __List__
 *
 * A an element with the role of `list` for semantically grouping list items.
 *
 * This is the internal primitive used by other external components in the navigation system.
 */
export const List = forwardRef<HTMLDivElement, ListProps>(_List);

/**
 * Returns true if the child is either:
 * - a list item
 * - one or more list items wrapped only in presentational elements
 */
function isValidChild(element: Element): boolean {
	/**
	 * These elements are ignored by screen readers.
	 */
	if (element.tagName === 'STYLE' || element.tagName === 'SCRIPT') {
		return true;
	}

	/**
	 * Recursive case:
	 * If this is a presentational element (no semantics) then it is valid
	 * if all of its children are valid.
	 *
	 * This check is currently much stricter than it needs to be
	 * (only allowing `div` and not allowing an explicit role).
	 *
	 * We can make it looser in the future if necessary.
	 */
	if (element.tagName === 'DIV' && element.getAttribute('role') === null) {
		return Array.from(element.children).every(isValidChild);
	}

	return element.getAttribute('role') === 'listitem';
}
