import {
	getEmojiFromDraftPageProperties,
	getEmojiFromPublishedPageProperties,
} from '@confluence/emoji-title/entry-points/transformer';
import {
	getEmojiFromTitle,
	removeEmojiFromTitle,
} from '@confluence/emoji-title/entry-points/helpers';
import { getEditorVersionFromPageProperties } from '@confluence/content-utils';

import { VIRTUAL_ROOT_ID } from './treeParameters';

// Parse the date and return the year. If the format is incorrect, return VIRTUAL_ROOT_ID instead.
export const getYear = (date) => {
	if (!date) {
		return VIRTUAL_ROOT_ID;
	}
	const dateFragments = date.split('-');
	return dateFragments && dateFragments.length ? dateFragments[0] : VIRTUAL_ROOT_ID;
};

export const isBlogTreeTruncated = (blogs) => Boolean(blogs?.pageInfo?.hasNextPage);

export const getBlogpostNodes = (data) => data?.content?.nodes || [];

const LATEST_YEAR = new Date().getFullYear().toString();

export const transformBlogToTreeItem = (page, year) => {
	const emojiPublished = getEmojiFromPublishedPageProperties(page);
	const emojiDraft = getEmojiFromDraftPageProperties(page);
	const status = page.status;

	const nativeEmoji = getEmojiFromTitle(page.title);
	const hasSelectedEmoji = (emojiPublished || emojiDraft).length > 0;
	const hasNativeEmoji = nativeEmoji.length > 0;
	const emojiFromProperties = status === 'draft' ? emojiDraft : emojiPublished;

	const emoji = hasSelectedEmoji ? emojiFromProperties : nativeEmoji;

	// editor should be v1, v2, or ""
	const editorVersion = getEditorVersionFromPageProperties(page);

	return {
		id: page.id,
		children: [],
		hasChildren: false,
		isExpanded: false,
		isChildrenLoading: false,
		data: {
			title: hasNativeEmoji && !hasSelectedEmoji ? removeEmojiFromTitle(page.title) : page.title,
			emoji,
			webui: page.links.webui,
			isScrollTo: false,
			status: page.status,
			blank: page.blank,
			editorVersion,
			createdYear: year,
		},
	};
};

export const populateFlattenedBlogPages = (blogPage, flattenedBlogPages, drafts) => {
	const isDraft = blogPage.status === 'draft';

	if (isDraft) {
		// Keep drafts in a separate list, sort before merging it with the latest year's published blogs
		drafts.push({
			id: blogPage.id,
			lastModifiedDate: blogPage?.metaData?.lastModifiedDate,
		});
	}

	// For draft blogs, show under the current year
	const year = isDraft ? LATEST_YEAR : getYear(blogPage.history.createdDate);

	// Check if the year is already created. If not, create it
	if (flattenedBlogPages.hasOwnProperty(year)) {
		if (!isDraft) {
			flattenedBlogPages[year].children.push(blogPage.id);
		}
	} else {
		flattenedBlogPages[year] = {
			id: year,
			hasChildren: true,
			children: isDraft ? [] : [blogPage.id],
			isExpanded: true,
			data: {
				title: year,
				webui: '',
				blank: false,
			},
		};
		if (isDraft) {
			// Draft year is always the latest year
			flattenedBlogPages[VIRTUAL_ROOT_ID].children.unshift(year);
		} else {
			flattenedBlogPages[VIRTUAL_ROOT_ID].children.push(year);
		}
	}
	// Create a new entry in flattenedBlogPages for the current blogpost
	flattenedBlogPages[blogPage.id] = transformBlogToTreeItem(blogPage, year);
	return { flattenedBlogPages, drafts };
};

/**
 * Takes blogtree payload and transforms it to a structure consumable by
 * Atlaskit/Tree.
 *
 * @param {object} currentPage
 * @param {object} rootLevelPages
 * @returns {object} tree
 */

export const flattenTreeData = (blogPagesData) => {
	let flattenedBlogPages = {
		VIRTUAL_ROOT_ID: {
			id: VIRTUAL_ROOT_ID,
			children: [],
			hasChildren: true,
			isExpanded: true,
			isChildrenLoading: false,
			data: {
				title: 'root',
				webui: '',
				blank: false,
			},
		},
	};

	if (!blogPagesData) {
		return flattenedBlogPages;
	}
	const blogPages = blogPagesData && blogPagesData.nodes ? blogPagesData.nodes : [];

	let drafts = [];
	blogPages.forEach((blogPage) => {
		({ flattenedBlogPages, drafts } = populateFlattenedBlogPages(
			blogPage,
			flattenedBlogPages,
			drafts,
		));
	});

	// Sort draft blogs by lastModifiedDate and push to the top of the latest year
	if (drafts.length !== 0) {
		drafts.sort((a, b) => a.lastModifiedDate - b.lastModifiedDate);
		const draftIds = drafts.map((draft) => draft.id);
		flattenedBlogPages[LATEST_YEAR].children = draftIds.concat(
			flattenedBlogPages[LATEST_YEAR].children,
		);
	}

	return flattenedBlogPages;
};
