import { createHook, createStore } from 'react-sweet-state';

type BlogTreeStoreState = {
	shouldForceRefetch: boolean;
};

const initialState: BlogTreeStoreState = {
	shouldForceRefetch: false, // this is just a boolean that we toggle between false and true to force a refetch in blog tree
};

const actions = {
	forceRefetchBlogTree:
		() =>
		({ setState, getState }) => {
			const currentState = getState();
			setState({ shouldForceRefetch: !currentState.shouldForceRefetch });
		},
};

const BlogTreeStore = createStore({
	initialState,
	actions,
});

export const useBlogTreeStore = createHook(BlogTreeStore);
